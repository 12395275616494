



































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ChevronIcon from '@/app/ui/assets/chevron_down.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import controller from '@/app/ui/controllers/PhoneCodeController'
import { PhoneCode } from '@/domain/entities/PhoneCode'

export interface PhoneCodeOption {
  country: string
  phoneCode: string
}

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: {
    ChevronIcon,
  },
})
export default class InputPhoneInternational extends Vue {
  @Prop({ default: '' }) private value!: string
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: 'Cari Kode Negara' }) private searchPlaceholder!: string
  @Prop({ default: 'Eg: 81367722112' }) private placeholder!: string
  @Prop({ default: '+62'}) private phoneCode!: string
  @Prop({ default: false }) private isError!: boolean

  controller = controller
  public isDropdownVisible = false
  public keyword = ''
  public selectedPhoneCode: PhoneCodeOption = {
    country: '',
    phoneCode: this.phoneCode,
  }
  public phoneCodeList: PhoneCodeOption[] = []
  public actualPhoneCode = ''

  async mounted(): Promise<void> {
    if (!this.controller.phoneCodes?.length) {
      await this.controller.getAll()
    }
    this.mapPhoneCodeList()
  }

  get filteredOptions(): PhoneCodeOption[] {
    return this.onFilterOptions(this.phoneCodeList, this.keyword)
  }

  get displayValue(): string {
    return this.value.replace(this.actualPhoneCode, '') 
  }

  set displayValue(value: string) {
    const parseNumberOnly = Utils.numberOnly(value)
    const inputElement = this.$refs.inputPhoneNumber as HTMLInputElement
    inputElement.value = parseNumberOnly
    this.$emit('input', parseNumberOnly.replace(this.actualPhoneCode, ''))
  }

  get phoneCodeValue(): { value: string, phoneCodeList: PhoneCodeOption[] } {
    return {
      value: this.value,
      phoneCodeList: this.phoneCodeList
    } 
  }

  private onClickDropdown(): void {
    if (!this.disabled) {
      this.isDropdownVisible = !this.isDropdownVisible
      this.keyword = ''
    }
  }

  private onClickOutside(): void {
    this.keyword = ''
    this.isDropdownVisible = false
  }

  private onClickPhoneCode(item: PhoneCodeOption): void {
    this.selectedPhoneCode = item
    this.isDropdownVisible = false
    this.$emit('phoneCodeChange', item.phoneCode)
    this.swapFirstPhoneCodeList()
    this.keyword = ''
    this.actualPhoneCode = item.phoneCode
    if (item.phoneCode === '+62' && this.value.charAt(0) !== '8') {
      this.clearInputPhoneValue()
    }
  }

  private swapFirstPhoneCodeList(): void {
    const selectedPhoneCodeIndex = this.phoneCodeList.findIndex(
      item => item.phoneCode === this.selectedPhoneCode.phoneCode
    )
    if (selectedPhoneCodeIndex !== -1) {
      const selectedPhoneCode = this.phoneCodeList[selectedPhoneCodeIndex]
      this.phoneCodeList.splice(selectedPhoneCodeIndex, 1)
      this.phoneCodeList.unshift(selectedPhoneCode)
    }
  }

  private setSelectedPhoneCode(): void {
    this.selectedPhoneCode = this.phoneCodeList.find((item) => {
      return item.phoneCode === (this.actualPhoneCode || this.phoneCode)
    }) ?? this.phoneCodeList[0]
  }

  private onFilterOptions(
    options: PhoneCodeOption[],
    keyword: string
  ): PhoneCodeOption[] {
    return options.filter(item => {
      return item.country.toLowerCase().startsWith(keyword.toLowerCase())
    })
  }

  private setInitialPhoneCode(): void {
    this.actualPhoneCode = this.phoneCodeList.filter((code) => {
      return this.value.startsWith(code.phoneCode)
    })[0]?.phoneCode ?? this.phoneCode 

    this.$emit('phoneCodeChange', this.actualPhoneCode || this.phoneCode)
    if (this.phoneCodeList.length) {
      this.setSelectedPhoneCode()
    }
  }

  private mapPhoneCodeList(): void {
    if (this.controller.phoneCodes?.length) {
      this.phoneCodeList = <PhoneCodeOption[]>this.controller.phoneCodes?.map(item => {
        return {
          country: item.country,
          phoneCode: item.countryCode,
        }
      })
    }
  }

  private clearInputPhoneValue(): void {
    const inputElement = this.$refs.inputPhoneNumber as HTMLInputElement
    inputElement.value = ''
    this.$emit('input', '')
  }

  @Watch('controller.phoneCodes')
  onPhoneCodeListChange(data: PhoneCode[]): void {
    this.phoneCodeList = <PhoneCodeOption[]>data?.map(item => {
      return {
        country: item.country,
        phoneCode: item.countryCode,
      }
    })    
  }

  @Watch('phoneCodeValue')
  onPhoneCodeValueChange(data: { value: string, phoneCodeList: PhoneCodeOption[] }): void {
    if (data.value.length) {
      if (!this.actualPhoneCode && data.phoneCodeList.length) {
        this.setInitialPhoneCode()
        this.swapFirstPhoneCodeList()
        this.$emit('input', data.value.replace(this.actualPhoneCode, ''))
      }
    }
  }

  @Watch('value')
  onValueChange(data: string): void {
    if (!data.includes('+')) {
      if (data.charAt(0) !== '8' && this.actualPhoneCode === '+62') {
        this.clearInputPhoneValue()
      }
    }
  }
}
