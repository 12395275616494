








































import TextInput from '@/app/ui/components/TextInput/index.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

export interface DropdownOption {
  label: string
  value: string
}

@Component({
  inheritAttrs: false,
  components: {
    TextInput,
  },
})
export default class InputSuggestion extends Vue {
  @Prop({ default: '' }) public placeholder!: string
  @Prop({ default: '' }) public value!: string
  @Prop({ default: () => [] }) public options!: DropdownOption[]
  @Prop({ default: false }) public isError!: boolean

  isDropdownVisible = false

  public onClickOutside(): void {
    this.isDropdownVisible = false
  }

  public onFocus(): void {
    this.isDropdownVisible = true
  }

  public onSelect(value: string): void {
    this.$emit('input', value)
    this.isDropdownVisible = false
  }

  @Watch('value')
  public onValueChange(): void {
   this.$emit('input', this.value.toUpperCase()) 
  }
}
