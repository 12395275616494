import { PhoneCode } from '@/domain/entities/PhoneCode'
import { container } from 'tsyringe'
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { PhoneCodePresenter } from '../presenters/PhoneCodePresenter'
import store from '../store'

export interface PhoneCodeState {
  phoneCodes?: PhoneCode[]
}

@Module({
  name: 'PhoneCode',
  namespaced: true,
  dynamic: true,
  store,
})
class PhoneCodeController extends VuexModule implements PhoneCodeState {
  private presenter: PhoneCodePresenter = container.resolve(PhoneCodePresenter)
  public phoneCodes?: PhoneCode[] = []

  @Action({ rawError: true })
  public async getAll(): Promise<void> {
    this.presenter
      .getAll()
      .then(resp => {
        this.setPhoneCodes(resp.phoneCodes)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch Phone Code Failed',
          text:
            err.status === 400 || err.status === 422
              ? err.error.message.en
              : 'Something Went Wrong',
          type: 'error',
          duration: 5000,
        })
      })
  }

  @Mutation
  private setPhoneCodes(phoneCodes?: PhoneCode[]): void {
    this.phoneCodes = phoneCodes
  }
}

export default getModule(PhoneCodeController)
