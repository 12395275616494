



















import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  inheritAttrs: false,
  components: { TextInput },
})
export default class ProgramTextInput extends Vue {
  @Prop({ default: 'text' }) private type!: string
  @Prop({ default: false }) private allowNullNumber!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop() private fieldDescription!: string
  @Prop() private fieldPrefix!: string
  @Prop() private errorMessage!: string

  fileName = ''

  get displayValue(): string {
    if (this.type === 'currency') {
      if (this.allowNullNumber && !this.$attrs.value) {
        return this.$attrs.value
      }

      return Utils.currencyDigit(Number(this.$attrs.value)).toString()
    }

    return this.$attrs.value
  }
  set displayValue(modifiedValue: string) {
    if (modifiedValue === '') {
      this.$emit('input', '')
      return
    }

    if (this.type === 'currency' || this.type === 'number') {
      let newValue = Number(modifiedValue.replace(/[^0-9]+/g, ''))
      if (isNaN(newValue)) {
        newValue = 0
      }

      // $emit the event so that parent component gets it
      this.$emit('input', newValue)

      return
    }

    this.$emit('input', modifiedValue)
  }

   private onInputChange($event: { target: { files: { name: string }[] } }) {
    if (this.type === 'file') {
      this.fileName = `${$event.target.files[0].name}`
      this.$emit('change', $event.target.files[0])
    }
  }
}
